import { AgreementData } from '@/types'
import { Network } from '@monorepo/best-in-slot'
import { BitcoinNetworkType } from 'sats-connect'

export const BTC_NETWORK_TYPE = BitcoinNetworkType.Mainnet // For Xverse sats-connect library
export const BTC_NETWORK = Network.mainnet // For Monorepo best-in-slot library

export const IS_DEV = process.env.NODE_ENV === 'development'
export const IS_NOT_DEV = process.env.NODE_ENV !== 'development'
export const IS_TEST = process.env.NODE_ENV === 'test'

export const LOCAL_STORAGE_LAST_CONNECTED_WALLET = 'last_connected_wallet'

export enum CollectionEnum {
  fmp = 'fmp',
}

export const CollectionId = (collection: CollectionEnum) => {
  switch (collection) {
    case CollectionEnum.fmp:
      return 1
    default:
      throw new Error(`CollectionId not found for ${collection}`)
  }
}

export const AGREEMENT_DATA: AgreementData = {
  title: 'Membership Terms & Conditions',
  description:
    'Below is the membership agreement.  In order to use the site, you must agree to the terms and conditions.  Please read the agreement and sign your name below to indicate you agree to the terms and conditions.  You will sign this agreement with your private key indicating the owner of the wallet agrees to the terms and conditions.',
  link: 'https://docs.google.com/document/d/1qb2UtCed87VJ3UIg-WbjaVCUgAQUn7SG0_uRFztnGqU/edit',
  version: 1,
}

export const IDENFY = {
  authTokenUrl: 'https://ivs.idenfy.com/api/v2/token',
  webUIRedirectUrl: 'https://ivs.idenfy.com/api/v2/redirect?authToken=',
  apiKey: process.env.IDENFY_API_KEY,
  apiSecret: process.env.IDENFY_API_SECRET,
}

export const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV

export const IDENFY_REDIRECT_URL =
  VERCEL_ENV === 'development'
    ? 'https://dashboard.ezmining.xyz'
    : VERCEL_ENV === 'production'
      ? `https://${process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL}`
      : `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`
