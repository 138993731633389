import logger from '@monorepo/logger'
import { BISHolder } from './bestinslot-types'

const log = logger.getLogger('bestinslot/flattenBISCollectionHolders')

export function flattenBISCollectionHolders(collectionHolders: BISHolder[]) {
  if (collectionHolders.length === 0) {
    return []
  }

  log.debug('collectionHolders.length: ', collectionHolders.length)

  const inscriptionWalletPairs: {
    inscriptionId: string
    walletAddress: string
  }[] = []

  // Iterate through the "wallets" array and extract the necessary information
  collectionHolders.forEach((walletData) => {
    const inscription_ids = walletData.inscription_ids || []

    inscription_ids.forEach((inscriptionId) => {
      inscriptionWalletPairs.push({
        inscriptionId,
        walletAddress: walletData.wallet,
      })
    })
  })

  return inscriptionWalletPairs
}
