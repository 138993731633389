// https://docs.bestinslot.xyz/reference/api-reference/ordinals-and-brc-20-and-bitmap-v3-api-mainnet+testnet/collections

import logger from '@monorepo/logger'
import {
  BISCollectionActivityResponse,
  BISCollectionHoldersResponse,
  BISCollectionInscriptionsResponse,
  BISWalletInscriptionsResponse,
} from './bestinslot-types'
import { Network } from 'bitcoin-address-validation'

const log = logger.getLogger('app/bestinslot/index')

const BESTINSLOT_API_KEY = process.env.BESTINSLOT_API_KEY

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

// IF testing, set as BESTINSLOT_API_KEY=WILL_NOT_WORK
// This is a test key that will not work, which we should not be using in tests, so is fine
if (BESTINSLOT_API_KEY) {
  log.debug('BESTINSLOT_API_KEY: ', BESTINSLOT_API_KEY)
}

export const BestinslotHeaders = {
  'x-api-key': BESTINSLOT_API_KEY || '',
}

// Make this type available to others
export { Network } from 'bitcoin-address-validation'
const BISBaseUrl = (network: Network) => (network == Network.mainnet ? 'https://api.bestinslot.xyz' : 'https://testnet.api.bestinslot.xyz')

export const BISEndpoints = {
  // Collections
  GetCollectionHolders: (network: Network, collection: string) =>
    `${BISBaseUrl(network)}/v3/collection/holders?slug=${collection}&sort_by=wallet_addr&order=asc`,
  // &offset=${offset}&count=100`,

  GetCollectionInscriptions: (network: Network, collection: string, offset: number) =>
    `${BISBaseUrl(network)}/v3/collection/inscriptions?slug=${collection}&sort_by=inscr_num&order=asc&offset=${offset}&count=100`,
  // GetCollectionListings = 'https://api.bestinslot.xyz/v3/collection/listings',

  // activity_filter 6 = transferred + sold
  GetCollectionActivity: (network: Network, collection: string, lastNewSatpoint: string) =>
    // eslint-disable-next-line max-len
    `${BISBaseUrl(network)}/v3/collection/activity?slug=${collection}&activity_filter=6&sort_by=ts&order=asc&offset=0&count=100${lastNewSatpoint === '' ? '' : '&last_new_satpoint='}${lastNewSatpoint}`,

  // Wallets
  GetWalletInscriptions: (network: Network, address: string) =>
    // eslint-disable-next-line max-len
    `${BISBaseUrl(network)}/v3/wallet/inscriptions?address=${address}&sort_by=inscr_num&order=desc&offset=0&count=2000&exclude_brc20=true&cursed_only=false`,

  // GetWalletListings = 'https://api.bestinslot.xyz/v3/wallet/listings',
  // GetWalletActivity = 'https://api.bestinslot.xyz/v3/wallet/activity',
}

const MAX_RETRIES = 5
const RETRY_DELAY = 13000

const Get = async <T>(endpoint: string): Promise<T> => {
  log.debug('Get', { endpoint })

  for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
    try {
      const response = await fetch(endpoint, { headers: BestinslotHeaders })

      if (response.status === 200) {
        return (await response.json()) as T
      }

      if (attempt < MAX_RETRIES) {
        log.warn(`Attempt ${attempt} failed with ${response.status} status. Retrying in ${RETRY_DELAY / 1000} seconds...`)
        await sleep(RETRY_DELAY)
        continue
      }

      throw new Error(`Get ${endpoint} failed with status ${response.status}`)
    } catch (err) {
      if (attempt === MAX_RETRIES) {
        if (err instanceof Error) log.error(err.message)
        else log.error(JSON.stringify(err))
        throw err
      }

      log.warn(`Attempt ${attempt} failed. Retrying in ${RETRY_DELAY / 1000} seconds...`)
      await sleep(RETRY_DELAY)
    }
  }

  // This line should never be reached due to the throw in the final iteration,
  // but TypeScript needs it for type safety
  throw new Error('Max retries reached')
}

export const BISGetCollectionHolders = ({ network, collection }: { network: Network; collection: string }) =>
  Get<BISCollectionHoldersResponse>(BISEndpoints.GetCollectionHolders(network, collection))

export const BISGetCollectionInscriptions = ({ network, collection, offset }: { network: Network; collection: string; offset: number }) =>
  Get<BISCollectionInscriptionsResponse>(BISEndpoints.GetCollectionInscriptions(network, collection, offset))

export const BISGetCollectionActivity = ({
  network,
  collection,
  lastNewSatpoint,
}: {
  network: Network
  collection: string
  lastNewSatpoint: string
}) => Get<BISCollectionActivityResponse>(BISEndpoints.GetCollectionActivity(network, collection, lastNewSatpoint))

export const BISGetWalletInscriptions = ({ network, address }: { network: Network; address: string }) =>
  Get<BISWalletInscriptionsResponse>(BISEndpoints.GetWalletInscriptions(network, address))

export { flattenBISCollectionHolders } from './flattenBISCollectionHolders'
