/*
 * Shared types within the app
 */
export interface RewardWalletAddresses {
  btcWallet: string
}

export interface AgreementData {
  title: string
  description: string
  link: string
  version: number
}

export enum SupportedWallets {
  XVERSE = 'xverse',
  MAGIC_EDEN = 'magicEden',
  UNISAT = 'unisat',
  PHANTOM = 'phantom',
  LEATHER = 'leather',
}
