'use client'

import { Verifier } from 'bip322-js'
import * as Sentry from '@sentry/nextjs'
import { BTC_NETWORK_TYPE, LOCAL_STORAGE_LAST_CONNECTED_WALLET } from '@/config'
import useLocalStorage from './useLocalStorage'
import {
  Address,
  AddressPurpose,
  AddressType,
  GetAddressResponse,
  getProviderById,
  getProviders,
  Provider,
  signMessage as signSatsConnectMessage,
} from 'sats-connect'
import { useToast } from './useToast'
import logger from '@monorepo/logger'
import { getSessionOrdAddress, signOut } from '@/supabase/supabase-client'
import { refreshPageCache } from '@/actions'
import router from 'next/router'
import { PUBLIC_PAGE_ROUTES } from '@/config/publicPageRoutes'
import { SupportedWallets } from '@/types'

type SignWithSignatureResponse = {
  signedSignature: string | undefined
}

const log = logger.getLogger('useWallets hook')

function bytesToBase64(bytes: any) {
  const binString = String.fromCodePoint(...bytes)
  return btoa(binString)
}

function handleWalletSigningError(e: any) {
  Sentry.captureException(e, { tags: { feature: 'WalletSigning' } })
  if (e.message) {
    if (e.message.includes('User rejected') || e.message.includes('User declined')) {
      throw new Error('User canceled signing')
    }
    throw e
  }

  // Leather wallet error
  if (e?.error?.message?.includes('User rejected')) {
    throw new Error('User canceled signing')
  }

  log.error(e)

  throw new Error(e)
}

const useWallets = () => {
  const [lastConnectedWallet] = useLocalStorage(LOCAL_STORAGE_LAST_CONNECTED_WALLET)
  const { toast } = useToast()

  const anyWindow: any = window
  const unisat = anyWindow.unisat

  const getPhantomProvider = () => {
    if ('phantom' in window) {
      const provider = anyWindow.phantom?.bitcoin

      if (provider && provider.isPhantom) {
        return provider
      }
    }
  }

  const getLeatherProvider = () => {
    if ('LeatherProvider' in window) {
      const provider = anyWindow.LeatherProvider

      if (provider && provider.isLeather) {
        return provider
      }
    }
  }

  const getXverseProvider = (): Provider | undefined => {
    const providers = getProviders()
    const xverseProvider = providers.find((provider) => provider.id === 'XverseProviders.BitcoinProvider')
    if (xverseProvider) {
      const providerObject = getProviderById(xverseProvider.id)
      return providerObject
    } else {
      log.error('Xverse not installed?')
    }
  }

  const getMagicEdenProvider = (): Provider | undefined => {
    log.debug('anyWindow.magicEden.bitcoin: ', anyWindow.magicEden.bitcoin)
    if (anyWindow.magicEden.bitcoin && anyWindow.magicEden.bitcoin.isMagicEden) return anyWindow.magicEden.bitcoin
  }

  /*
   * Strictly for logging in with Xverse or MagicEden
   */
  const getMagicEdenOrXverseAddress = async (connectResponse: GetAddressResponse) => {
    const ordWallet = connectResponse.addresses.find((address: Address) => address.purpose === AddressPurpose.Ordinals)
    const pmtWallet = connectResponse.addresses.find((address: Address) => address.purpose === AddressPurpose.Payment)

    if (!pmtWallet || !pmtWallet?.address) {
      log.error('pmt not found')
      throw new Error('pmt not found')
    }

    if (!ordWallet || !ordWallet?.address) {
      log.error('ord not found')
      throw new Error('ord not found')
    }

    return {
      ordWalletAddress: ordWallet.address,
      pmtWalletAddress: pmtWallet.address,
    }
  }

  const getPhantomAddress = async () => {
    const phantomProvider = getPhantomProvider()
    const accounts = await phantomProvider.requestAccounts()

    const ordWallet = accounts.find((address: Address) => address.purpose === AddressPurpose.Ordinals)
    const pmtWallet = accounts.find((address: Address) => address.purpose === AddressPurpose.Payment)

    if (!pmtWallet || !pmtWallet?.address) {
      log.error('pmt not found')
      throw new Error('pmt not found')
    }

    if (!ordWallet || !ordWallet?.address) {
      log.error('ord not found')
      throw new Error('ord not found')
    }

    return {
      ordWalletAddress: ordWallet.address,
      pmtWalletAddress: pmtWallet.address,
    }
  }

  const getLeatherAddress = async () => {
    const leatherProvider = getLeatherProvider()
    const { result } = await leatherProvider.request('getAddresses')

    const ordWallet = result.addresses.find((address: any) => address.type === AddressType.p2tr)
    const pmtWallet = result.addresses.find((address: any) => address.type === AddressType.p2wpkh)

    if (!pmtWallet || !pmtWallet?.address) {
      log.error('pmt not found')
      throw new Error('pmt not found')
    }

    if (!ordWallet || !ordWallet?.address) {
      log.error('ord not found')
      throw new Error('ord not found')
    }

    log.debug('ordWallet', ordWallet)
    log.debug('pmtWallet', pmtWallet)

    return {
      ordWalletAddress: ordWallet.address,
      pmtWalletAddress: pmtWallet.address,
    }
  }

  const getUnisatAddress = async () => {
    const accounts = await unisat.requestAccounts()

    if (!accounts[0].startsWith('bc1p')) {
      throw { code: 400, message: 'Ensure Taproot address type is selected' }
    }

    log.debug('ordWalletAddress: ', accounts[0])
    // Return the first address. Should only be one.
    return accounts[0]
  }

  const ensureCorrectNetworkUnisat = async () => {
    const network = await unisat.getNetwork()
    if (network !== 'livenet') {
      toast({ description: 'Switching Unisat to BTC Mainnet' })
      const switchNetwork = await unisat.switchNetwork('livenet')
      log.debug('switchNetwork: ', switchNetwork)
    }
  }

  const signWithSignature = async ({
    signature,
    ordWalletAddress,
    provider,
  }: {
    signature: string
    ordWalletAddress: string
    provider: any
  }) => {
    const payload = {
      address: ordWalletAddress,
      message: signature,
      network: { type: BTC_NETWORK_TYPE },
    }

    let signedSignature = undefined

    try {
      await signSatsConnectMessage({
        getProvider: provider,
        payload,
        onFinish: (signedMsg) => {
          signedSignature = signedMsg
        },
        onCancel: () => {
          throw new Error('User canceled signing')
        },
      })

      return { signedSignature }
    } catch (e: any) {
      handleWalletSigningError(e)
    }
  }

  const signXverseMessage = async (message: string, ordWalletAddress: string) => {
    const msg = await signWithSignature({
      provider: getXverseProvider,
      signature: message,
      ordWalletAddress,
    })

    return msg
  }

  const signMagicEdenMessage = async (message: string, ordWalletAddress: string) => {
    const msg = await signWithSignature({
      provider: getMagicEdenProvider,
      signature: message,
      ordWalletAddress,
    })

    return msg
  }

  const signUnisatMessage = async (message: string, ordWalletAddress: string) => {
    try {
      const signature = await unisat.signMessage(message)
      const isValidSignature = Verifier.verifySignature(ordWalletAddress, message, signature)

      if (!isValidSignature) {
        throw 'Signature not valid. Check that you have the correct wallet and address type selected.'
      }

      return { signedSignature: signature }
    } catch (e: any) {
      handleWalletSigningError(e)
    }
  }

  const signPhantomMessage = async (message: string, ordWalletAddress: string) => {
    try {
      const phantomProvider = getPhantomProvider()
      const encodedMessage = new TextEncoder().encode(message)
      const { signature } = await phantomProvider.signMessage(ordWalletAddress, encodedMessage)
      const isValidSignature = Verifier.verifySignature(
        ordWalletAddress,
        new TextDecoder().decode(encodedMessage),
        bytesToBase64(signature),
      )
      if (!isValidSignature) {
        throw 'Signature not valid. Check that you have the correct wallet selected.'
      }
      return { signedSignature: signature }
    } catch (e: any) {
      handleWalletSigningError(e)
    }
  }

  const signLeatherMessage = async (message: string, ordWalletAddress?: string) => {
    try {
      const leatherProvider = getLeatherProvider()

      const response = await leatherProvider.request('signMessage', {
        message,
        paymentType: AddressType.p2tr,
      })

      const isValidSignature = Verifier.verifySignature(
        ordWalletAddress || response.result.address,
        response.result.message,
        response.result.signature,
      )

      if (!isValidSignature) {
        throw 'Signature not valid. Check that you have the correct wallet selected.'
      }

      return { signedSignature: response.result.signature }
    } catch (e) {
      handleWalletSigningError(e)
    }
  }

  const signUserOut = async (toastMsg: string) => {
    try {
      toast({ variant: 'destructive', title: 'Error', description: toastMsg })
      await signOut()
      await refreshPageCache({ path: '/', type: 'layout' })
      setTimeout(() => {
        void router.push(PUBLIC_PAGE_ROUTES._login)
        return
      }, 1000)
    } catch (e) {
      log.error('error signing out')
    }
  }

  const signMessage = async (message: string): Promise<SignWithSignatureResponse> => {
    let signMessageMethod
    const sessionOrdAddress = await getSessionOrdAddress()

    log.debug('signMessage sessionOrdAddress: ', sessionOrdAddress)

    if (lastConnectedWallet === SupportedWallets.UNISAT) {
      signMessageMethod = await signUnisatMessage(message, sessionOrdAddress)
    } else if (lastConnectedWallet === SupportedWallets.MAGIC_EDEN) {
      signMessageMethod = await signMagicEdenMessage(message, sessionOrdAddress)
    } else if (lastConnectedWallet === SupportedWallets.XVERSE) {
      signMessageMethod = await signXverseMessage(message, sessionOrdAddress)
    } else if (lastConnectedWallet === SupportedWallets.PHANTOM) {
      signMessageMethod = await signPhantomMessage(message, sessionOrdAddress)
    } else if (lastConnectedWallet === SupportedWallets.LEATHER) {
      signMessageMethod = await signLeatherMessage(message, sessionOrdAddress)
    } else {
      log.error('No wallet in localStorage')
      Sentry.captureException('Wallet removed from localStorage', {
        extra: {
          sessionOrdAddress,
          message,
        },
      })

      await signUserOut('Preferred wallet not detected. Please sign in again.')
    }

    return signMessageMethod as SignWithSignatureResponse
  }

  return {
    lastConnectedWallet,
    getPhantomProvider,
    getLeatherProvider,
    getMagicEdenProvider,
    getXverseProvider,
    getMagicEdenOrXverseAddress,
    getPhantomAddress,
    getLeatherAddress,
    getUnisatAddress,
    ensureCorrectNetworkUnisat,
    signXverseMessage,
    signMagicEdenMessage,
    signUnisatMessage,
    signPhantomMessage,
    signLeatherMessage,
    signMessage,
  }
}

export default useWallets
