import logger from '@monorepo/logger'
import { useState } from 'react'

const log = logger.getLogger('useLocalStorage')

function useLocalStorage<T>(key: string, initialValue?: T) {
  // State to store the current value
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? (JSON.parse(item) as T) : initialValue
    } catch (error) {
      log.warn(`Error reading localStorage key "${key}":`, error)
      return initialValue
    }
  })

  // Function to set a new value in localStorage
  const setValue = (value: T | ((val: T | undefined) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      log.warn(`Error setting localStorage key "${key}":`, error)
    }
  }

  // Function to remove the value from localStorage
  const removeValue = () => {
    try {
      window.localStorage.removeItem(key)
      setStoredValue(undefined)
    } catch (error) {
      log.warn(`Error removing localStorage key "${key}":`, error)
    }
  }

  return [storedValue, setValue, removeValue] as const
}

export default useLocalStorage
